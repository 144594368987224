.swagger-ui .wrapper section.models {
  display: none;
}

.ui.sidebar.menu, .ui.attached.menu {
  background-color: var(--navy);
}

.ui.sidebar.menu .ui.attached.menu {
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.ui.sidebar.menu .ui.attached.menu div.header {
  font-weight: 700;
  font-size: .875rem;
  color: var(--white) !important;
}

.ui.sidebar.menu .ui.attached.menu a[href='/apis/search'] {
  font-weight: 500 !important;
  font-size: var(--default-font-size) !important;
  line-height: var(--default-line-height);
  color: var(--white) !important;
}

.swagger-section.pusher {
  background-color: var(--lighter-grey);
}

.swagger-ui .information-container p,
.swagger-ui .information-container a,
.swagger-ui .information-container h1,
.swagger-ui .information-container button {
  color: var(--dark-grey);
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
}

.swagger-ui .information-container a {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark-grey);
  font-size: var(--default-font-size);
  line-height: 1.125rem;
}

.swagger-ui .information-container p {
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.swagger-ui .information-container button {
  font-weight: 600;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}

.swagger-ui .information-container h1.ui.header {
  font-size: 1.625rem;
  line-height: 1.9375rem;
}

#select-app-text {
  height: auto;
  font-size: 0.75rem;
  align-self: auto;
  text-align: left;
  font-family: var(--default-font-family);
  font-weight: 500;
  color: red;
  line-height: normal;
  font-stretch: normal;
  margin-left: 0.1rem;
  margin-bottom: 0;
  padding-bottom: 0;
}