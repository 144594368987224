.modal--header h1 {
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: 700;
  margin: 0;
}

.ui.modal .modal--header {
  border: none;
  padding: 0;
}

.modal--close-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: .875rem;
  height: .875rem;
  line-height: 0;
  padding: 0;
}

.modal--close-icon-button img {
  width: 100%;
  height: 100%;
  opacity: .26;
  transition: opacity 80ms ease-out;
}

.modal--close-icon-button img:hover {
  opacity: .45;
  transition: opacity 80ms ease-out;
}

.modal--www-close-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: .875rem;
  height: .875rem;
  line-height: 0;
  padding: 0;
}

.modal--www-close-icon-button img {
  width: 100%;
  height: 100%;
  opacity: .90;
  transition: opacity 80ms ease-out;
}

.modal--www-close-icon-button img:hover {
  opacity: .45;
  transition: opacity 80ms ease-out;
}

.ui.modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 2rem;
  gap: 1.5rem;
  isolation: isolate;
  border-radius: .5rem;
}

.ui.modal .actions {
  background: none;
  padding: 0;
  border: none;
  text-align: center;
}

.ui.modal .content {
  padding: 0;
}

.ui.modal .ui.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ui.modal .ui.form .field {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .ui.modal {
    min-width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .ui.modal {
    width: 53%;
  }
}

@media only screen and (min-width: 992px) {
  .ui.modal {
    width: 455px;
  }
}