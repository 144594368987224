.www-card {
  background-color: var(--white);
  padding: 1.5rem 1rem;
  border-radius: .5rem;
  box-shadow: 0 0 1.5rem #00000026;
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 1rem;
  max-width: 25rem;
}

.www-card--content-section {
  font-size: var(--default-font-size);
  line-height: 1.4rem;
  font-weight: 500;
  display: flex;
}

.www-card--icon-section {
  display: flex;
  user-select: none;
}
