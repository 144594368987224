/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/
html {
  font-size: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#portal-wrapper {
  position: relative;
  height: -webkit-fill-available;

  /* This is so that the portal-wrapper fills up the body. */
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */
  flex-grow: 1; /* NEW, */

  /* This is for the parent div so that a particular child div (main-area-wrapper in this case)
   fills up remaining space. */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

#main-area-wrapper {
  /* This is so that the main-area-wrapper fills up the portal-wrapper. */
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */
  flex-grow: 1; /* NEW, */

  /* This is so that a particular div in the main-area-wrapper fills up remaining space. */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

h1, h2, h3, h4, h5 {
  line-height: initial;
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--dark-grey);
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow:
          0 0 0 1px #E0B4B4 inset,
          0 2px 4px 0 rgba(34, 36, 38, 0.12),
          0 2px 10px 0 rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 0.9em;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 1em;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

/* This allows the text on react-alert alerts to remain as it, rather than being automatically converted to uppercase */
#__react-alert__ div div div {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  text-transform: initial !important;
  font:inherit !important;
  padding: 8px 10px;
}
