.headerRow {
  user-select: none;
}

.appliancesIndent {
  text-indent: 3rem;
  list-style-position: inside;
}

.appliancesRow {
  border-top: none;
  background-color: #E0E0E0;
}
