.ui.menu.navbar {
  background-color: var(--white);
  position: relative;
}

p {
  padding: 2px 20px;
  text-align: center;
  font-weight: 700;
  font-size: 0.75rem;
}

.login-button {
  padding-right: 1.5rem;
}

/* Including commented-out code in case the yellow Register button is requested later:
.div-for-register-button {
  height: min-content;
  margin-right: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}*/

.www-logo-img {
  width: auto;
  margin-left: 1rem;
}
