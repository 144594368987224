#home-content {
  background-image: url('../../public/custom-content/images/www-home-page-background.webp'), linear-gradient(to bottom right, #6090d5, #91abd0, #cfd7ce, #fae9c6, #feebd9);
  height: inherit;
  background-size: cover;
}

#home-content h1,
#home-content p {
  color: var(--dark-grey);
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
}

#home-content h1 {
  font-weight: var(--emphasized-header-font-weight);
  font-size: var(--large-header-font-size);
  line-height: var(--large-header-line-height);
}

#home-content p {
  padding-left: 0;
  text-align: left;
}

#home-content .card-section {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  max-width: none !important;
}

#home-content .title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#home-content .home-page-graphic {
  min-width: 300px;
  user-select: none;
}