#getting-started-section {
  background-color: var(--lighter-grey);
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */

  flex-grow: 1;
}

#getting-started-section, #getting-started-section h2,
#getting-started-section h3 {
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
}

#getting-started-section h2 {
  font-weight: var(--emphasized-header-font-weight);
  font-size: 1.625rem;
  line-height: 1.9375rem;
}

#getting-started-section h3 {
  font-weight: var(--header-font-weight);
  font-size: 1rem;
  line-height: 1.21rem;
}

#getting-started-section p {
  text-align: left;
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: 1.125rem;
}

#getting-started-section hr {
  margin-left: 1.125rem;
}

#getting-started-section p a {
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark-grey);
}

#getting-started-section p a:active,
#getting-started-section p a:hover,
#getting-started-section p a:focus {
  text-decoration: underline;
}

#getting-started-section table tr td {
  padding-left: 1.125rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

#getting-started-section table tr td:first-child {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

#getting-started-section table tr td:last-child {
  padding-left: 1.875rem;
  line-height: 1.125rem;
}

#getting-started-section table td {
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
}
