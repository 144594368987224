.ui.primary.buttons .pill-button,
.ui.primary.pill-button {
  color: var(--dark-grey);
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
  font-weight: var(--header-font-weight);
  font-size: var(--default-font-size);
  line-height: var(--default-line-height);
  background-color: var(--primary-yellow);
  border-radius: 1.5rem;
  padding: .625rem 1.25rem;
}

.ui.pill-button.large {
  font-size: 1rem;
  line-height: 1.21rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ui.primary.buttons .pill-button:active,
.ui.primary.buttons .pill-button:focus,
.ui.primary.buttons .pill-button:hover,
.ui.primary.pill-button:active,
.ui.primary.pill-button:focus,
.ui.primary.pill-button:hover {
  color: var(--dark-grey);
  background-color: var(--primary-yellow-hover);
}