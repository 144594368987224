#portal-footer {
    bottom: 0;
    width: 100%;
    min-height: 4.875rem;
    max-height: 4.875rem;
    position: relative;
    background-color: white;
}

#portal-footer-text {
    color: rgba(127, 123, 123, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.625rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}