:root {
    --default-font-family: Inter, 'Lato', 'Helvetica Neue', Arial, Helvetica, Sans-Serif;
}

#overall {
    margin: 0;
    height: 100%;
}

.main-section {
    min-height: 100%;
    position: relative;
    border-color: transparent;
    background-color: rgba(245, 245, 245, 1);
    overflow: auto;
}

.login-module-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 3.75rem;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: transparent;
    background-color: rgba(255, 255, 255, 1);
}

.www-logo-in-header {
    width: 7.5rem;
    display: flex;
    padding: 0.625rem 0 0 0;
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    border: none;
}

.www-logo-img {
    width: 7.5rem;
    height: 2.625rem;
    position: relative;
}

.central-rectangle {
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7.5rem;
    margin-bottom: 6.25rem;

    height: min-content;
    display: flex;
    padding: 2rem;
    position: relative;
    box-shadow: 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.15000000596046448);
    align-items: center;
    border-color: transparent;
    border-radius: 0.5rem;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}

#title-text {
    height: auto;
    font-size: 1.125rem;
    align-self: stretch;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 1.4rem;
    text-decoration: none;
}

#email-section {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 1.4rem;
    flex-direction: column;
}

.label-for-input {
    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: left;
    font-family: var(--default-font-family);
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0.313rem;
    text-decoration: none;
}

#email-input {
    width: 17.5rem;
    height: 0.99rem;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(209, 209, 209, 1);
    border-style: solid;
    border-width: 0.063rem;
    border-radius: 0.25rem;
    padding: .9375rem 1.125rem;
}

#password-section {
    display: flex;
    position: relative;
    align-items: flex-start;
    border-color: transparent;
    margin-bottom: 1.4rem;
    flex-direction: column;
}

#password-input {
    width: 17.5rem;
    height: 0.99rem;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(209, 209, 209, 1);
    border-style: solid;
    border-width: 0.063rem;
    border-radius: 0.25rem;
    margin-bottom: 0.313rem;
    padding: .9375rem 1.125rem;
}

#forgot-your-password-text {
    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: left;
    font-family: var(--default-font-family);
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: underline;
}

#error-message-text {
    display: block;
    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 500;
    color: red;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 1.2rem;
}

#able-to-revoke-text {
    height: auto;
    font-size: 0.75rem;
    align-self: stretch;
    text-align: center;
    font-family: Helvetica;
    font-weight: 300;
    line-height: 1.125rem;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 1.4rem;
    text-decoration: none;
}

#sign-in-button {
    width: 7.5rem;
    display: flex;
    padding: 0.625rem 1.25rem;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    background: #EEB111;
    border-radius: 1.4rem;
    border: none;

    /* stuff I added - at least some related to text */
    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

.footer {
    bottom: 0;
    width: 100%;
    height: 4.875rem;
    position: absolute;
    background-color: white;
}

.footer-text {
    color: rgba(127, 123, 123, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.625rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}

#permission {
    display: none;
}

#logos-bar {
    width: 20.313rem;
    height: 5rem;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: green;
    margin-bottom: 1.4rem;
}

#whirlpool-logo {
    top: 1.938rem;
    left: 1.096rem;
    width: 7.5rem;
    height: 2.375rem;
    position: absolute;
}

#connection-symbol {
    top: 2.313rem;
    left: 8.75rem;
    width: 4.813rem;
    height: 1.375rem;
    position: absolute;
}

#partner-logo-placeholder {
    top: 0;
    left: 13.875rem;
    width: 5.875rem;
    height: 6.063rem;
    position: absolute;
}

#connect-with-title {
    width: auto;
    height: auto;
    font-size: 1.125rem;
    align-self: stretch;
    text-align: left;
    font-family: var(--default-font-family);
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 1.4rem;
    text-decoration: none;
}

#explanation-wording {
    height: auto;
    font-size: 0.875rem;
    align-self: stretch;
    text-align: left;
    font-family: var(--default-font-family);
    font-weight: 400;
    line-height: 1.25rem;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 1.4rem;
    text-decoration: none;
}

#allow-button {
    width: 7.5rem;
    display: flex;
    padding: 0.625rem 1.25rem;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 1.5rem;
    margin-bottom: 1.4rem;
    justify-content: center;
    background-color: #EEB111;

    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    text-decoration: none;
}

#not-now-button {
    width: 7.5rem;
    display: flex;
    padding: 0.625rem 1.25rem;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-color: transparent;
    border-radius: 1.5rem;
    justify-content: center;

    height: auto;
    font-size: 0.75rem;
    align-self: auto;
    text-align: center;
    font-family: var(--default-font-family);
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none;
}