.form--text-input label,
.form--text-input .ui.input input {
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
}

.ui.form .form--text-input.field label {
  font-weight: 500;
  font-size: .75rem;
  line-height: .9375rem;
}

.form--text-input .ui.input input {
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.0625rem;
  border-radius: .25rem;
  padding: .9375rem 1.125rem;
}

/* FireFox Only */
@-moz-document url-prefix() {
  .form--text-input .ui.input input[type='password'] {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
  }
}

.form--text-input .ui.input input:focus {
  border-radius: .25rem;
}

.form--text-input .ui.input input:not(:focus) {
  border-color: var(--light-grey);
}

.form--text-input .ui.input::placeholder {
  color: var(--light-grey);
  opacity: 1;
}