:root {
  --dark-grey: #1F1F1F;
  --default-font-family: 'Inter', 'Lato', 'Helvetica Neue', Arial, Helvetica, Sans-Serif;
  --default-font-size: .75rem;
  --default-letter-spacing: .02em;
  --default-line-height: .9375rem;
  --disabled-text-color: var(--light-grey);
  --emphasized-header-font-weight: 900;
  --header-font-size: 1.625rem;
  --header-font-weight: 700;
  --header-line-height: 1.9375rem;
  --large-header-font-size: 2.75rem;
  --large-header-line-height: 3.3125rem;
  --light-grey: #D1D1D1;
  --lighter-grey: #F5F5F5;
  --modal-close-grey: #00000042;
  --navy: #1A2F3C;
  --primary-yellow-hover: #D9A214;
  --primary-yellow: #EEB111;
  --white: #FFFFFF;
}

.ui.header {
  color: var(--dark-grey);
  font-family: var(--default-font-family);
  letter-spacing: var(--default-letter-spacing);
}