#select-appliance-text {
  height: auto;
  font-size: 0.75rem;
  align-self: auto;
  text-align: center;
  font-family: var(--default-font-family);
  font-weight: 500;
  color: red;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0.3rem;
}